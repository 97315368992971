
const SNMEDIA = {
	onReady: () => {
		SNMEDIA.initMediaRemoveModal();
	},



	initMediaRemoveModal: () => {
        const triggerMediaRemove = document.querySelectorAll('.trigger-media-remove');

        triggerMediaRemove.forEach( (trigger) => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();

                let type = trigger.dataset.modalType || 'info';
                let title = trigger.dataset.modalTitle || trigger.innerHTML;
                let body = trigger.dataset.modalBody || '';
                let button = trigger.dataset.modalButton || 'OK';
                
                Bulma().alert({
                    type: type,
                    title: title,
                    body: body,
                    confirm: {
                        label: button,
                        onClick: function() {
                            SNMEDIA.context = trigger;
                            SNMEDIA.removeMedia();
                        },
                    },
                    cancel: { 
                        label: 'Abbrechen' 
                    }
                });
            });
        });
    },


	removeMedia: () => {
		const action =	SNMEDIA.context.getAttribute('data-action');
		const method = 'POST';
		const body = new FormData();
		body.append('sn-fields[wpid]', SNMEDIA.context.getAttribute('data-wpid'));
		body.append('sn-fields[request-type]', SNMEDIA.context.getAttribute('data-request-type'));
		body.append('file', SNMEDIA.context.getAttribute('data-file'));

		fetch(action, {
            method,
			body,
			credentials: "same-origin",
			headers: {
		      "X-WP-Nonce": snJS.nonce,
		    },
		})
		.then((response) => response.json())
		.then((response) => {
			SNMEDIA.context.closest('.has-media').classList.add('is-hidden');
			SNMEDIA.context.closest('.has-media').nextElementSibling.classList.remove('is-hidden');

		})
		.catch((error) => {
            Bulma().alert({
                type: 'danger',
                title: 'Fehler',
                body: 'Bei Ihrer Anfrage ist eine Fehler aufgetreten. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support.',
                confirm: {
                    label: 'OK',
                }
            });
		})
		;
	},
				
				
				
	updateMedia: (medias) => {
		Object.entries(medias).forEach( (media) => {
			const [key, value] = media;
			let input = document.querySelector('#' + key);
			
			input.closest('.has-upload').classList.add('is-hidden');

			let image = input.closest('.has-upload').previousSibling;
			image.classList.remove('is-hidden');
			image.querySelector('img').setAttribute( 'src', value.url );
		});
	}
}



SNMEDIA.onReady();